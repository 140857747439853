import {
  ChangeDetectionStrategy,
  Component,
  computed, EventEmitter,
  Injector,
  model,
  OnInit, Output,
  SecurityContext
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ProgressService} from '../../services/progress.service';
import {IMenuButtonAction, IMenuButtonItem, MenuButtonComponent} from '../menu-button/menu-button.component';
import {DomSanitizer} from '@angular/platform-browser';
import {NotificationsService} from '../../services/notifications.service';

export interface IHeaderNotification {
  id: number;
  image?: string;
  desc: string;
  date: string;
  viewed?: boolean;
  // for UI
  descHTML?: string;
  actions?: IMenuButtonItem[];
}

@Component({
  selector: 'ui-notification-popup',
  standalone: true,
  templateUrl: './notification-popup.component.html',
  styleUrl: './notification-popup.component.scss',
  imports: [
    MenuButtonComponent
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationPopupComponent implements OnInit {
  @Output() action = new EventEmitter<IMenuButtonAction>();

  items = model.required<IHeaderNotification[]>();
  newCount = computed(() => this.items().filter(i => !i.viewed).length);
  sanitizedItems = computed(() => {
    return this.items().map(it => {
      const html = this.san.sanitize(SecurityContext.HTML, it.desc);

      it.actions = [
        {id: 'markAsRead', text: 'Mark as read' },
        {id: 'unsubscribe', text: 'Unsubscribe' }
      ];
      if (it.viewed) {
        it.actions.splice(0, 1);
      }
      return {
        ...it,
        descHTML: html,
      }
    })
  });



  rowActions: IMenuButtonItem[] = [
    {id: 'markAsRead', text: 'Mark as read' },
    {id: 'unsubscribe', text: 'Unsubscribe' }
  ];



  constructor(private injector: Injector,
              private router: Router,
              private ps: ProgressService,
              private route: ActivatedRoute,
              private ns: NotificationsService,
              private san: DomSanitizer) {
  }

  get globalActions(): IMenuButtonItem[]   {
    const mark = {id: 'markAsRead', text: 'Mark all as read'};
    return [
      ...(this.newCount() ? [mark] : []),
      {id: 'settings', text: 'Notification settings'}
    ];
  }

  ngOnInit() {
    this.items.set([
      {
        id: 1,
        image: '',
        desc: 'Dmitry Maslennikov published a new application <b>Docker InterSystems Extension</b>',
        date: '10 Aug, 2024'
      },
      {
        id: 2,
        image: '',
        desc: 'Muhammad Waseem left a review in <b>Docker InterSystems Extension</b>',
        date: '12 Aug, 2024',
        viewed: true
      },
      {
        id: 3,
        image: '',
        desc: '<b>Docker InterSystems Extension</b> new release <b>Version: 0.1.4</b>',
        date: '14 Aug, 2024',
        viewed: true
      }
    ]);
  }

  onGlobalAction(action: IMenuButtonAction) {
    this.action.emit(action);
    switch (action.item.id) {
      case 'settings': this.router.navigateByUrl('/portal/profile/subscriptions'); break;
      case 'markAsRead': this.markAllAsRead(); break
    }
  }

  private async markAllAsRead() {
    this.ns.onMarkAsRead.emit(this.items().filter(n => !n.viewed).map(n => n.id));
    this.items.update(items => {
      items.forEach(n => n.viewed = true);
      return [...items];
    });
  }
}
