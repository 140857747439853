<!-- Header -->
<div class="header">
  <h4>Notifications {{ newCount() ? ('(' + newCount() + ')') : '' }}</h4>
  <ui-menu-button class="transparent" icon="ui-icon-circle-more" [items]="globalActions"
                  (select)="onGlobalAction($event)"></ui-menu-button>
</div>

@if (sanitizedItems().length) {
  <div class="list">
    @for (n of sanitizedItems(); track $index) {
      <div class="item" (mouseleave)="menu.close()">
        <!-- Logo -->
        <div class="logo">
          <img src="/assets/img/package.svg">
        </div>

        <!-- Text -->
        <div>
          <div class="desc" [innerHTML]="n.descHTML"></div>
          <div class="date">{{ n.date }}</div>
        </div>


        <!-- Dot and actions -->
        <div class="actions">
          <ui-menu-button #menu class="transparent" icon="ui-icon-circle-more" [items]="n.actions || []"></ui-menu-button>
          @if (!n.viewed) {
            <div class="dot"></div>
          }
        </div>
      </div>
    }
  </div>
} @else {
  <div class="no-data">
    <div class="ui-icon-subscribe"></div>
    No notifications yet!
  </div>
}
