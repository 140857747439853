import {CanActivateFn, ResolveFn, Router} from '@angular/router';
import {ISidebarItem} from '../../../oex-ui-kit/components/sidebar/sidebar.component';
import {of} from 'rxjs/internal/observable/of';
import {inject} from '@angular/core';
import {AuthService} from '../authService';

export const oexSidebarItems: ResolveFn<ISidebarItem[]> = () => {
  const auth = inject(AuthService);
  const items: ISidebarItem[] = [
    {label: 'Edit profile', url: 'profile/personal'},
    {label: 'All applications', url: 'products'},
    {label: 'Statistics', url: 'statistics'},
    {label: 'Bookmarks', url: 'bookmarks'},
    {label: 'Reviews', url: 'reviews'},
    // {label: 'Contest and Awards', url: 'contest'},
    {label: 'Notification settings', url: 'profile/subscriptions'},
    ...(auth.isModerator ? [{label: 'App quality management', url: 'app-quality'}] : [])
  ];

  return of(items);
}
